import React, { Component } from 'react';
import Projects from "../components/projects/projects";
import Connect from "../components/connect/connect";

class Work extends Component{
    render() {
        return (
            <main>
              <Projects/>
              <Connect/>
            </main>
        )
    }
}

export default Work;
