import React, { Component } from 'react';
import styles from './showcase.module.scss';
import ModalVideo from 'react-modal-video';
import modalStyles from '../../../node_modules/react-modal-video/scss/modal-video.scss';

class Showcase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            projects: [],
            isOpen: false,
            videoId: 'eOmZvMEL-NA',
        }
    }

    componentDidMount() {
        getData().then((data) => {
            this.setState({
                loaded: true,
                projects: data
            });
        });
    }

    openModal () {
        this.setState({isOpen: true})
    }

    renderProjects() {
        const items = this.state.projects.map((data) => {
            return (
                <a href={"#"+ data.id}
                   key={data.id}
                   onClick={(event) => this.displayVideo(event, data.youtubeId)}>
                    <li>
                        <img src={data.image} alt={data.name}/>
                    </li>
                    <p>{data.description}</p>
                </a>
            )
        });

        return (
            <section className={styles.showcase}>
                <header>
                    <h3>Featured Work</h3>
                </header>
                <ul>
                    {items}
                </ul>
                <ModalVideo className={modalStyles} isOpen={this.state.isOpen} videoId={this.state.videoId} onClose={() => this.setState({isOpen: false})} />
            </section>
        )
    }

    displayVideo(event, youtubeId) {
        event.preventDefault();
        this.setState({videoId: youtubeId});
        this.openModal();
    }

    render() {
        if(this.state.loaded) {
            return this.renderProjects();
        }
        else return (
            <div>Loading</div>
        )
    }
}

function getData(){
  return fetch('/fixtures/work.json', {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) => response.json())
    .then((responseData) => {
        return responseData.projects.filter(function (item) {
            return item.featured === true;
        });
    })
    .catch(error => console.warn(error));
}

export default Showcase;
