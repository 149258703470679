import React, { Component } from 'react';
import styles from './hero.module.scss';

class Hero extends Component{
  render() {
    return (
      <section className={styles.hero}>
        <div className={styles.content}>
          <h1>
            Front-end <span>Engineer</span>
            <img src="/images/jessepinuelas.svg" alt=""/>
          </h1>
          <p>JavaScript / Sass / HTML / Accessibility &amp; User-interface Design</p>
        </div>
      </section>
    )
  }
}

export default Hero;
