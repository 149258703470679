import React, { Component } from 'react';
import styles from './title.module.scss';

class Title extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header className={styles.title}>
        <h1>{this.props.headline}</h1>
        <h4>{this.props.subHeadline}</h4>
      </header>
    )
  }
}

export default Title;
