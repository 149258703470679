import React, { Component } from 'react';
import styles from './connect.module.scss';

class Connect extends Component{
    render() {
        return (
            <section className={styles.connect}>
                <h3>Connect</h3>

                <ul className={styles.connectOptions}>
                    <li>
                        <a href="https://twitter.com/jessepinuelas"
                           target="_blank"
                           rel="noreferrer noopener">
                            <img src="/images/twitter.svg" alt="connect with Jesse Pinuelas on twitter"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://www.linkedin.com/in/jessepinuelas"
                           target="_blank"
                           rel="noreferrer noopener">
                            <img src="/images/linkedin.svg" alt="connect with Jesse Pinuelas on linked in"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCPQZ0WP-BoSvb9j8v0hdFBQ"
                           target="_blank"
                           rel="noreferrer noopener">
                            <img src="/images/youtube.svg" alt="connect with Jesse Pinuelas on you tube"/>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:me@jessepinuelas.com?subject=Jesse Piñuelas 2.0"
                           target="_blank"
                           rel="noreferrer noopener">
                            <img src="/images/email.svg" alt="connect with Jesse Pinuelas via email"/>
                        </a>
                    </li>
                </ul>
            </section>
        )
    }
}

export default Connect;
