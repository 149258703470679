import React, { Component } from 'react';
import { Link } from "react-router-dom";
import styles from "../projects/projects.module.scss";

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.getData().then((data) => {
      this.setState({
        loaded: true,
        projects: data
      });
    });
  }

  renderProjects() {
    const items = this.state.projects.map((data) => {
      return (
        <Link to={{pathname: `/work/${data.id}`, state: {project: data}}} key={data.id}>
          <img src={data.image} alt={data.name}/>
          <p>{data.description}</p>
        </Link>
      )
    });

    return (
      <section className={styles.projects}>
        <header>
          <h3>PROJECTS / GAMES / CAMPAIGNS / CONCEPTS</h3>
        </header>
        <ul>
          {items}
        </ul>
      </section>
    )
  }

  getData(){
    return fetch('/fixtures/work.json', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData.projects;
      })
      .catch(error => console.warn(error));
  }

  render() {
    if(this.state.loaded) {
      return this.renderProjects();
    }
    else return false
  }
}

export default Projects;
