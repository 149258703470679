import React, { Component } from 'react';
import Title from "../components/title/title";
import Copy from '../components/copy/copy';
import Connect from "../components/connect/connect";

class About extends Component{
    render() {
        return (
            <main>
              <Title headline='About' subHeadline='Front-end Engineer & User-interface Design'/>
              <Copy/>
              <Connect/>
            </main>
        )
    }
}

export default About;
