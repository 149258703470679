import React, { Component } from 'react';
import styles from './footer.module.scss';

class Footer extends Component {
    render() {
        return (
            <footer className={styles.footer}>
                <div className={styles.footerContainer}>
                    <div className={styles.footerItem}>
                        <p><span>&copy;</span> 2020 Jesse Piñuelas. All rights reserved.</p>
                    </div>
                    <div className={styles.footerItem}>
                        <p>Handcrafted with <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">React</a>, <a href="https://sass-lang.com/" rel="noopener noreferrer" target="_blank">Sass</a> & <a href="https://nodejs.org" rel="noopener noreferrer" target="_blank">Node.js</a></p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
