import React from 'react';
import ReactDom from 'react-dom';
import styles from './mobile-nav.module.scss';
import {Link} from "react-router-dom";

const MobileNav = (props) => {
  console.log('props:', props);

  if(!props.show) {
    return null;
  }

  return (
    ReactDom.createPortal(
      <div className={styles.mobileNav}>
        <img className={styles.close} src="/images/close.svg" alt="" onClick={props.handleClose}/>
        <div className={styles.container}>
          <h3 className={styles.brand}>
            <a href="/"><strong>Jesse</strong> Piñuelas</a>
          </h3>
          <ul className={styles.options}>
            <li><a href="/work">Work</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
      </div>,
      document.getElementById('mobile-nav-root')
    )

  )
};

export default MobileNav;
