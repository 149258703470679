import React, { Component } from 'react';
import Title from "../../components/title/title";
import styles from "../../pages/contact/contact.module.scss";
import Connect from "../../components/connect/connect";

class Contact extends Component{
    render() {
        return (
            <main>
              <Title headline='Contact' subHeadline=''/>

              <section className={styles.contact}>
                <div className={styles.contactItem}>
                  <h3>Just want to say hello?</h3>
                  <p>I always enjoy meeting new creatives and professionals or simply meeting interesting folks.
                    If that's you give me a shout at the email address below.
                  </p>
                  <p>Email: <a href="mailto:me@jessepinuelas.com?subject=Jesse Piñuelas 2.0 Contact">me@jessepinuelas.com</a></p>
                </div>
                <div className={styles.contactItem}>
                  <h3>Did you encounter a problem or error</h3>
                  <p>I would appreciate it if you took the time to explain what the problem is so that I can fix it. Please
                    send an email describing the problem you had and I will fix it right away.</p>
                  <p>Support: <a href="mailto:support@jessepinuelas.com?subject=Jesse Piñuelas 1.5 Support">support@jessepinuelas.com</a></p>
                </div>
              </section>

              <Connect/>
            </main>
        )
    }
}

export default Contact;
