import React, { Component } from 'react';
import styles from './copy.module.scss';

class Copy extends Component {
  render() {
    return (
      <div className={styles.copy}>
        <div className={styles.copyBlock}>
          <p>I have a background in Interactive and Multi-Media Design with a strong emphasis on
            Front-end Technology, and User Interface Design. I often wear a few hats wherever I
            am which involves developing web applications, creating concepts, building prototypes,
            generating documentation and working with several departments to ensure rich user
            experiences and great code quality.
          </p>

          <p>I’ve had the privilege of working with some amazing agencies/companies and brands such
            as: Experian, Kelley Blue Book, Mattel, Hyundai, Corbis, Chevy, Toyota, U.S. Army, Nuko
            Toys, Warner Brothers, Fox Searchlight, FX, RAPP, Sabertooth Interactive,
            Carmichael Lynch and Deutsch.
          </p>
        </div>

        <div className={styles.copyBlock}>
          <p>I can honestly say that I love what I do. I am the proud father of three, enjoy photography,
            teaching and building useful and creative things for the web.
          </p>
        </div>
      </div>
    )
  }
}

export default Copy;
