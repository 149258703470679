import React, { Component } from 'react';
import { Link } from "react-router-dom";
import styles from './navbar.module.scss';
import MobileNavModule from "../mobile-nav/mobile-nav";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileNav: false
    }
  }

  displayMobileNav(){
    console.log('display mobile nav');
    this.setState({
      showMobileNav: true
    })
  }

  dismissMobileNav(){
    this.setState({
      showMobileNav: false
    })
  }

  render() {
    return (
      <nav className={styles.navbar}>
        <div className={styles.navbarContainer}>
          <ul className={styles.navbarBrand}>
            <li>
              <Link to="/">
                <strong>Jesse</strong> Piñuelas
              </Link>
            </li>
          </ul>

          <ul className={styles.navbarItems}>
            <button className={styles.mobileMenuItem} onClick={this.displayMobileNav.bind(this)}>
              <img src="/images/menu.svg" alt=""/>
            </button>

            <li>
              <Link to="/work">Work</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li className={styles.socialItem}>
              <a href="https://www.linkedin.com/in/jessepinuelas"
                 target="_blank"
                 rel="noopener noreferrer">
                <img src="/images/linkedin.svg" alt=""/>
              </a>
            </li>
            <li className={styles.socialItem}>
              <a href="https://twitter.com/jessepinuelas"
                 target="_blank"
                 rel="noopener noreferrer">
                <img src="/images/twitter.svg" alt=""/>
              </a>
            </li>
          </ul>
        </div>

        <MobileNavModule show={this.state.showMobileNav} handleClose={this.dismissMobileNav.bind(this)}/>
      </nav>
    )
  }
}

export default Navbar;
