import React from 'react';
import { Route } from 'react-router-dom';
import Navbar from './components/nav-bar/nav-bar';
import Landing from './pages/landing';
import Work from './pages/work';
import WorkDetail from "./pages/work-detail/workDetail";
import Contact from './pages/contact/contact';
import About from './pages/about';
import Footer from './components/footer/footer';
import './app.scss';

function App() {
  return (
      <div className="app">
        <Navbar/>
        <Route path="/" exact component={Landing}/>
        <Route path="/work" exact component={Work}/>
        <Route path="/work/:id" exact component={WorkDetail}/>
        <Route path="/about" exact component={About}/>
        <Route path="/contact" exact component={Contact}/>
        <Footer/>
      </div>
  );
}

export default App;
