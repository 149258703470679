import React, { Component } from 'react';
import styles from './workdetail.module.scss';
import ModalVideo from 'react-modal-video';

let items = null;

class WorkDetail extends Component {
  constructor(props){
    super(props);

    this.state = {
      loaded: false,
      project: this.props.location.state.project,
      isOpen: false,
      videoId: this.props.location.state.project.youtubeId,
      url: this.props.location.state.project.url
    };

    this.openModal = this.openModal.bind(this)
  }

  openModal (event) {
    event.preventDefault();
    this.setState({isOpen: true})
  }

  componentDidMount() {
    this.setState({
      loaded: true
    });

    items = this.state.project.features.map((data, index) => {
      return (
        <ul key={index}>
          <li>
            <h3>{data.name}</h3>
            <p>{data.description}</p>
          </li>
          <li>
            <img src={data.image} alt=""/>
          </li>
        </ul>
      )
    });
  }


  render() {
    if(this.state.loaded) {
      return (
        <main>
          <section className={styles.hero}>
            <img src={this.state.project.hero} alt=""/>
          </section>

          <section className={styles.info}>
            <ul>
              <li>
                <h3>{this.state.project.description}</h3>
                <p>{this.state.project.blurb}</p>
                {this.state.videoId !== '' &&
                <a href="" onClick={(event) => this.openModal(event)}>View Reel</a>
                }

                {this.state.url !== '' &&
                <a href={this.state.url} target="_blank">Visit Site</a>
                }
              </li>
              <li>
                <img src={this.state.project.projectImage} alt=""/>
              </li>
            </ul>
          </section>

          <section className={styles.highlights}>
            {items}
          </section>

          <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.videoId} onClose={() => this.setState({isOpen: false})} />
        </main>
      )
    }
    else return (
      <div>Loading</div>
    )
  }
}

export default WorkDetail;
