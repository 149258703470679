import React, { Component } from 'react';
import Hero from "../components/hero/hero";
import Showcase from '../components/showcase/showcase';
import Connect from '../components/connect/connect';

class Landing extends Component{
    render() {
        return (
            <main>
                <Hero/>
                <Showcase/>
                <Connect/>
            </main>
        )
    }
}

export default Landing;
